html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}
.App {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background-color: purple;
}
.game {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: black;
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: orange;
  border-radius: 20px;
  border: 20px solid transparent;
}

/* The size of the logo on visiting the page */

.logo {
  max-width: 50%;
  max-height: 50%;
}

/* The size of badges in profile */

.badge {
  max-width: 25%;
  max-height: 25%;
  cursor: pointer;
}

/* The size of badge in badge information in profile */

.badgeInfo {
  max-width: 100%;
  max-height: 100%;
}

/* The size of the trophy in the game */

.trophy {
  max-width: 80%;
  max-height: 80%;
}

/* The purple box in the game which is called a pokedex. */

.question {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 45vh;
  width: 100%;
  background-color: black;
}

/* The purple box in the game which is called a pokedex. */

.pokedex {
  height: 50vh;
  width: 100%;
  background-color: purple;
}

/* The red form in login and Registration pages. */

.pokedexForm {
  height: 350px;
  width: 230px;
  margin: 1%;
  padding: 0px 20px 20px 20px;
  background-color: purple;
}

/* The red form in login and Registration pages. */

.badgeText {
  margin: 5%;
  color: white;
  padding: 0px 20px 20px 20px;
  background-color: darkred;
}

/* The black screen. */

.score {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 10vh;
  width: 100%;
  background-color: black;
  color: lightgreen;
}

/* Regular buttons in the app. */

.button {
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  margin: 4px;
  width: 45%;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: white;
  background-color: blue;
  border: none;
  border-radius: 25px;
  box-shadow: 0 5px darkblue;
}

.button:hover {background-color: cyan}

.button:active {
  box-shadow: 0 1px darkblue;
  transform: translateY(7px);
}

/* The four answer buttons in the game. */

.button-answer {
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  margin: 1%;
  width: 40%;
  height: 40px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: white;
  background-color: blue;
  border: none;
  border-radius: 25px;
  box-shadow: 0 5px darkblue;
}

#answerA {
  position: absolute;
  transform: translate(-110%, 10%)
}
#answerB {
  position: absolute;
  transform: translate(0%, 10%)
}
#answerC {
  position: absolute;
  transform: translate(-110%, 150%)
}
#answerD {
  position: absolute;
  transform: translate(0%,150%)
}

#allCategories {
  position: absolute;
  transform: translate(-370%, 500%)
}
#geography {
  position: absolute;
  transform: translate(-250%, 500%)
}
#history {
  position: absolute;
  transform: translate(-130%, 500%)
}
#entertainment {
  position: absolute;
  transform: translate(-10%, 500%)
}
#art {
  position: absolute;
  transform: translate(110%, 500%)
}
#science {
  position: absolute;
  transform: translate(230%, 500%)
}
/* The buttons at the top of the pokedex. */

.pokedex-button {
  -webkit-tap-highlight-color: transparent;
  margin: 1%;
  padding: 8px;
  border-radius: 100%;
}

/* The space at the top of the pokedex. */

.pokedexTop {
  height: 8vh;
}

/* The start button in the pokedex is pushed left. */

.pokedex-init {
  float: left;
}

/* The User and About button in the pokedex are pushed right. */

.users {
  float: right;
  padding: 2px;
  margin: 5px;
}

/* The buttons in the pokedex at the bottom that pick the generation of pokemon displayed. */

.buttonNav {
  -webkit-tap-highlight-color: transparent;
  width: 12%;
  margin: 2% 1% 2% 1%;
}

/* media query for mobiles */
@media only screen and (max-width:768px) {
  p {
      font-size: 8px;
      font-size: 4vw;
  }
}
